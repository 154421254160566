<template>
  <v-card :loading="loading" elevation="0">
    <v-alert  dense
             prominent
             type="warning"
    >
      <v-row align="center">
        <v-col class="grow">
          Максимум {{MAX_TEAM_SIZE}} участников в проекте.
        </v-col>
      </v-row>
    </v-alert>
    <v-card-title>Роли в проекте</v-card-title>
    <v-card-actions v-if="project.permissions.can_edit_roles">
      <v-btn small rounded :disabled="maxCapacity <= 0" class="mt-2" color="green" outlined right @click="addRoleDialog = true">
        <v-icon left>mdi-plus</v-icon>
        Добавить роль
      </v-btn>
      <ProjectAddRoleDialogComponent :maxCapacity="maxCapacity" :is-open="addRoleDialog" @close="addRoleDialog = false"/>

    </v-card-actions>

    <v-sheet v-for="role in project.roles" :key="role.id" class="pa-4 mt-4" color="gray" elevation="1">
      <v-row align="center">
        <v-col cols="12" lg="6">
          <v-text-field v-model="role.name" dense
                        label="Название роли" outlined/>
          <v-textarea hint="Данный текст отображается на странице Вакансии. Опишите, какими навыками должен обладать кандидат на эту роль. " v-model="role.tasks" dense
                        label="Задачи роли" outlined/>
        </v-col>
        <v-col cols="12" lg="5">
          <v-text-field :rules="[(value) => maxCapacity >= 0 || 'Вы привысили допустимое кол-во участников']" :min="1" :max="parseInt(role.quota) + maxCapacity" v-model="role.quota"  dense label="Квота"
                        outlined suffix="чел." type="number"/>
        </v-col>
        <v-col lg="1">
          <v-btn color="red" icon text @click="removeRole(role)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <br>
          <br>
        </v-col>

      </v-row>
      <v-btn :disabled="maxCapacity < 0" color="blue darken-2" text @click="updateRole(role)">
        Сохранить
      </v-btn>

    </v-sheet>

  </v-card>
</template>

<script>


import ProjectAddRoleDialogComponent from "@/components/Projects/ProjectsItem/ProjectAddRoleDialogComponent";

export default {
  name: "ProjectsItemRolesComponent",
  components: {
    ProjectAddRoleDialogComponent
  },
  mounted() {
    if (!this.$store.state.projects.currentProject.permissions.can_see_roles) {
      this.$router.back()
      return;
    }
  },
  computed: {
    project() {
      return this.$store.state.projects.currentProject;
    },

    readOnly() {
      return true
    },
    maxCapacity(){
      const quotas = this.project.roles.length > 0 ? this.project.roles.map(x => parseInt(x.quota)).reduce((x, y) => x + y) : 0;
      const m_size = this.MAX_TEAM_SIZE - quotas;
      return m_size;
    }
  },
  data() {
    return {
      loading: false,
      NAME_DEFAULT_ROLE: 'Руководитель проекта',
      MAX_TEAM_SIZE:  8,
      addRoleDialog: false
    }
  },
  methods: {
    removeRole(role) {
      this.loading = true;
      this.$store.dispatch('removeProjectRole', role).then(() => {
        this.loading = false
      })
    },

    updateRole(role) {
      this.loading = true;
      this.$store.dispatch('updateProjectRole', role).then(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
