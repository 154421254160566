<template>
    <v-dialog
        @click:outside="$emit('close')"
            v-model="isOpen"
            max-width="700"
    >
        <v-card  :loading="loading">
            <v-card-title>Добавить роль</v-card-title>
            <v-card-text>
                <v-text-field :error-messages="errors.name" v-model="role.name"  outlined placeholder="Название роли"></v-text-field>
                <v-textarea  hint="Данный текст отображается на странице Вакансии. Опишите, какими навыками должен обладать кандидат на эту роль. " :error-messages="errors.tasks"   v-model="role.tasks"  outlined placeholder="Задачи роли"></v-textarea>
                <v-text-field :rules="[(value) => maxCapacity >= value || 'Превышен лимит участников']" :max="maxCapacity" :error-messages="errors.quota"  v-model="role.quota" outlined placeholder="Квота" suffix="чел" type="number"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="black" @click="$emit('close')" right text>Закрыть</v-btn>
                <v-btn :disabled="maxCapacity < role.quota" :loading="loading" @click="save" class="darken-2" color="primary" right text>Добавить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "ProjectAddRoleDialogComponent",
        props: [
            'isOpen', 'maxCapacity'
        ],
        data(){
            return {
                role: {
                    name: null,
                    tasks: null,
                    quota: null
                },
                loading: false,
                errors: {}
            }
        },
        methods: {
            save(){
                this.loading = true;
                this.$store.dispatch('storeProjectRole', {...this.role, project_id: this.$store.state.projects.currentProject.id}).then(() => {
                    this.loading = false;
                    this.role = {name: null, tasks: null, quota: null}
                    this.errors = {};
                    this.$emit('close');
                }).catch((e) => {
                    this.loading = false;
                    this.errors = e.response.data.errors;
                })
            }
        }
    }
</script>

<style scoped>

</style>
